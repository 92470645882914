import { BattlesColors } from '~/features/battles/constants/colors';
import { FontSizesNumber, FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';
import type {
  ISharedKitButtonColorPreset,
  ISharedKitButtonSizePreset,
  ISharedKitButtonTypePreset,
} from '~/components/SharedKitButton/SharedKitButton.types';
import { BorderRadius } from '~/constants/common.constants';

/* Primary */
export const PrimaryBattlesButtonColorPreset = {
  defaultBackground: {
    colorStops: BattlesColors.MAIN,
    toDirection: GlobalUtils.Converting.toDegs(90),
  },
  hoverBackground: {
    colorStops: BattlesColors.MAIN_LIGHT,
    toDirection: GlobalUtils.Converting.toDegs(90),
  },
  loadingColor: Colors.NEUTRAL.WHITE_30,
  textColor: Colors.NEUTRAL.WHITE,
};

/* Secondary */
export const SecondaryBattlesButtonColorPreset = {
  defaultBackground: Colors.NEUTRAL.WHITE_15,
  hoverBackground: Colors.NEUTRAL.WHITE_30,
  loadingColor: Colors.NEUTRAL.WHITE_30,
  textColor: Colors.NEUTRAL.WHITE,
};

/* Tertiary */
export const TertiaryBattlesButtonColorPreset = {
  defaultBackground: Colors.NEUTRAL.BLACK_30,
  hoverBackground: Colors.NEUTRAL.BLACK_30,
  loadingColor: Colors.NEUTRAL.BLACK_30,
  textColor: Colors.NEUTRAL.WHITE,
};

/* Accent (Gold) */
export const AccentBattlesColorPreset = {
  defaultBackground: Colors.MAIN.GOLD,
  hoverBackground: Colors.NEUTRAL.WHITE,
  loadingColor: Colors.NEUTRAL.BLACK_30,
  textColor: Colors.NEUTRAL.BLACK,
};

/* Bot */
export const BotBattlesButtonColorPreset = {
  defaultBackground: BattlesColors.BOT_BLUE_DARK,
  hoverBackground: BattlesColors.BOT_BLUE,
  loadingColor: Colors.NEUTRAL.WHITE_30,
  textColor: Colors.NEUTRAL.WHITE,
};

const BattlesButtonSizePreset = (changeFn: ReturnType<typeof GlobalUtils.Media.changeByMedia>) => ({
  fontSize: changeFn(FontSizesNumber.MEDIUM, FontSizesNumber.SMALL),
  fontWeight: FontWeights.BOLD,
  height: changeFn(40, 32),
  type: changeFn('large', 'medium') as 'medium',
});

const colorsPresets = {
  accent: AccentBattlesColorPreset,
  bot: BotBattlesButtonColorPreset,
  primary: PrimaryBattlesButtonColorPreset,
  secondary: SecondaryBattlesButtonColorPreset,
  tertiary: TertiaryBattlesButtonColorPreset,
};

interface IButtonPreset {
  colorPreset: Partial<ISharedKitButtonColorPreset>;
  sizePreset: Partial<ISharedKitButtonSizePreset>;
  typePreset: Partial<ISharedKitButtonTypePreset>;
}

interface IButtonPresetRequired {
  colorPreset: ISharedKitButtonColorPreset;
  sizePreset: ISharedKitButtonSizePreset;
  typePreset: ISharedKitButtonTypePreset;
}

export const createPresetor = (
  changeFn: ReturnType<typeof GlobalUtils.Media.changeByMedia>,
  combineDefaultWith?: Partial<IButtonPreset>,
) => {
  return (preset: keyof typeof colorsPresets, extra?: Partial<IButtonPreset>): IButtonPresetRequired => {
    let presetConfig: IButtonPreset = {
      colorPreset: GlobalUtils.Objects.deepObjectCopy(colorsPresets[preset]),
      sizePreset: GlobalUtils.Objects.deepObjectCopy(BattlesButtonSizePreset(changeFn)),
      typePreset: {
        large: BorderRadius.SS,
        medium: BorderRadius.XS,
      },
    };

    if (combineDefaultWith && GlobalUtils.Objects.isObject(combineDefaultWith)) {
      presetConfig = GlobalUtils.Objects.deepObjectsMerge(presetConfig, combineDefaultWith);
    }

    if (extra && GlobalUtils.Objects.isObject(extra)) {
      presetConfig = GlobalUtils.Objects.deepObjectsMerge(presetConfig, extra);
    }

    return presetConfig as IButtonPresetRequired;
  };
};
