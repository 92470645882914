import type { LinearGradientConfiguration } from '~/types/deprecated.types';

export const BattlesGradientConfigs: Record<string, LinearGradientConfiguration> = {
  MULTICAST: {
    colorStops: ['#FF0F00', '#EA9312'],
    toDirection: GlobalUtils.Converting.toDegs(88),
  },
  MULTIFIX: {
    colorStops: ['#A133F8', '#E249FF'],
    toDirection: GlobalUtils.Converting.toDegs(88),
  },
};
