import { BorderRadius } from '~/constants/common.constants';

export const defineRadiusForButtons = computed(() => (btn: 'top' | 'bottom', fullBordered = false) => {
  const { xl } = useMedia();
  const { toPx } = GlobalUtils.Converting;

  const isDesktop = xl.value;

  const radiusesTop = {
    large: `${toPx(BorderRadius.XS)} ${toPx(BorderRadius.XS)} 0 0`,
    medium: `${toPx(BorderRadius.XS)} ${toPx(BorderRadius.XS)} 0 0`,
  };

  if (fullBordered) {
    if (isDesktop) radiusesTop.large = radiusesTop.medium = toPx(BorderRadius.XS);
    else radiusesTop.large = radiusesTop.medium = toPx(BorderRadius.XS3);
    return radiusesTop;
  }

  if (btn === 'bottom') {
    radiusesTop.large = radiusesTop.medium = `0 0 ${toPx(BorderRadius.XS)} ${toPx(BorderRadius.XS)}`;
  }

  if (!isDesktop) {
    if (btn === 'top') {
      radiusesTop.large = radiusesTop.medium = `${toPx(BorderRadius.XS3)} 0 0 ${toPx(BorderRadius.XS3)}`;
    }
    if (btn === 'bottom') {
      radiusesTop.large = radiusesTop.medium = `0 ${toPx(BorderRadius.XS3)} ${toPx(BorderRadius.XS3)} 0`;
    }
  }

  return radiusesTop;
});
