<template>
  <div :class="rootClasses" :style="rootStyles" class="battles-mini-case">
    <div class="battles-mini-case__content">
      <SharedMultiplier
        v-if="props.multiplier && +props.multiplier > 1"
        :multiplier="props.multiplier"
        class="content__multiplier"
      />
      <NuxtImg v-if="props.image" :src="props.image" class="content__image" />
    </div>
    <div v-if="slots.default" class="battles-mini-case__additional">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import type { IBattlesMiniCaseProps, IBattlesMiniCaseSlots } from './MiniCase.types';

const ACTIVE_BG_COLOR = '--active-bg-color';
const HOVER_BG_COLOR = '--hover-bg-color';

const ROOT_CLASS_NAME = 'battles-mini-case';

const slots = defineSlots<IBattlesMiniCaseSlots>();

const props = withDefaults(defineProps<IBattlesMiniCaseProps>(), {
  active: false,
  disableHoverEffects: false,
  multiplierTextProps: () => ({}),
});

const rootClasses = computed(() => ({
  [`${ROOT_CLASS_NAME}--active`]: props.active,
  [`${ROOT_CLASS_NAME}--no-hover-effects`]: props.disableHoverEffects,
}));

const rootStyles = computed(() => {
  const properties: CSSProperties = {};

  if (props.activeBgColor) properties[ACTIVE_BG_COLOR] = props.activeBgColor;
  if (props.hoverBgColor) properties[HOVER_BG_COLOR] = props.hoverBgColor;

  return properties;
});
</script>

<style src="./MiniCase.scss" scoped lang="scss" />
