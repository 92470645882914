<template>
  <div class="battles-round" :class="rootClasses" :style="rootStyles">
    <div class="battles-round__bg" />
    <div class="battles-round__content">
      <span :class="[textClasses, 'battles-round__content_text']">
        {{ currentRoundText }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';

import type { IRoundCircleProps } from '~/features/battles/components/RoundCircle/RoundCircle.types';
import { BattlesSpinnerTimeouts } from '~/features/battles/constants/rules';

const ROOT_CLASS_NAME = 'battles-round';
const MAIN_COLOR_VAR = '--main-color';
const MAIN_ACTIVE_COLOR_VAR = '--main-active-color';
const CIRCLE_COLOR_VAR = '--circle-color';
const CIRCLE_ACTIVE_COLOR_VAR = '--circle-active-color';

const props = withDefaults(defineProps<IRoundCircleProps>(), {
  active: false,
  done: false,
  textProps: () => ({}),
});

const { active, done, text } = toRefs(props);

const currentRoundText = ref(text.value);
const isAnimateRoundNumber = ref(false);

let currentRoundTimeout: ReturnType<typeof setTimeout>;
let animateRoundNumberTimeout: ReturnType<typeof setTimeout>;

watch(text, (val, oldVal) => {
  if (val === '0' || val <= oldVal) return;

  isAnimateRoundNumber.value = true;

  currentRoundTimeout = setTimeout(
    () => (currentRoundText.value = val),
    BattlesSpinnerTimeouts.animationRoundCircleNumber / 2,
  );
  animateRoundNumberTimeout = setTimeout(
    () => (isAnimateRoundNumber.value = false),
    BattlesSpinnerTimeouts.animationRoundCircleNumber,
  );
});

watch(done, (val) => {
  if (!val) return;

  clearTimeout(currentRoundTimeout);
  clearTimeout(animateRoundNumberTimeout);
});

const rootClasses = computed(() => ({
  [`${ROOT_CLASS_NAME}--active`]: active.value && !done.value,
  [`${ROOT_CLASS_NAME}--done`]: done.value,
}));

const rootStyles = computed(() => {
  const properties: CSSProperties = {};

  if (props.mainColor) properties[MAIN_COLOR_VAR] = props.mainColor;
  if (props.mainActiveColor) properties[MAIN_ACTIVE_COLOR_VAR] = props.mainActiveColor;
  if (props.circleColor) properties[CIRCLE_COLOR_VAR] = props.circleColor;
  if (props.circleActiveColor) properties[CIRCLE_ACTIVE_COLOR_VAR] = props.circleActiveColor;

  return properties;
});

const textClasses = computed(() => ({
  'battles-round__animation': isAnimateRoundNumber.value,
}));

const timeoutInMs = computed(() => ({
  roundCircleTime: `${BattlesSpinnerTimeouts.animationRoundCircle}ms`,
  roundNumberTime: `${BattlesSpinnerTimeouts.animationRoundCircleNumber}ms`,
}));
</script>

<style src="./RoundCircle.scss" scoped lang="scss"></style>
<style scoped lang="scss">
.battles-round {
  --round-circle-time: v-bind(timeoutInMs.roundCircleTime);
  --round-number-time: v-bind(timeoutInMs.roundNumberTime);
}
</style>
