<template>
  <div class="shared-mini-label" :class="rootClasses" :style="rootStyles">
    <SharedText class="shared-mini-label__text" :weight="FontWeights.BOLD" v-bind="props.textProps">
      <template #prepend>
        <span v-if="props.startSymbol">{{ props.startSymbol }}</span>
        <slot name="prepend"></slot>
      </template>
      <slot></slot>
      <template #append>
        <slot name="append"></slot>
      </template>
    </SharedText>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import type { IPropsSharedMiniLabel, ISlotsSharedMiniLabel } from './SharedMiniLabel.types';
import { FontWeights } from '~/types/SharedFont.types';

const ROOT_CLASS_NAME = 'shared-mini-label';
const ROOT_HEIGHT = '--root-height';
const ROOT_COLOR = '--root-color';

const slots = defineSlots<ISlotsSharedMiniLabel>();

const props = withDefaults(defineProps<IPropsSharedMiniLabel>(), {
  disableShadow: false,
  textProps: () => ({}),
});

const rootClasses = computed(() => ({
  [`${ROOT_CLASS_NAME}--no-shadow`]: props.disableShadow,
  [`${ROOT_CLASS_NAME}--flex`]: props.startSymbol || 'prepend' in slots || 'append' in slots,
}));

const rootStyles = computed(() => {
  const properties: CSSProperties = {};

  if (props.bgColor) {
    Object.assign(properties, GlobalUtils.CSS.parseColor(props.bgColor));

    if (!props.color) {
      if (Array.isArray(props.bgColor)) {
        properties[ROOT_COLOR] = GlobalUtils.CSS.pickTextColor(...props.bgColor);
      } else if (typeof props.bgColor !== 'object') {
        properties[ROOT_COLOR] = GlobalUtils.CSS.pickTextColor(props.bgColor);
      } else if (props.bgColor.colorStops) {
        properties[ROOT_COLOR] = GlobalUtils.CSS.pickTextColor(...props.bgColor.colorStops);
      }
    }
  }

  if (props.color) properties[ROOT_COLOR] = props.color;
  if (props.height) properties[ROOT_HEIGHT] = GlobalUtils.CSS.proceedCssValue(props.height);

  return properties;
});
</script>

<style src="./SharedMiniLabel.scss" scoped lang="scss"></style>
